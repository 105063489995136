@import '~antd/dist/antd.less';

@font-face {
  font-family: "Gellix";
  src: local("Gellix"), url("./assets/font/Gellix-Medium.woff") format("woff"),
  url("./assets/font/Gellix-Medium.ttf") format("ttf");
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FAFAFA;
}

.container {
  width: 100%;
  align-self: center;
  margin: auto;
}
.ant-select-item {
  font-size:  13.5px
}
.w-full{
  width: 100%!important;
}
.make-container() {
  // xs
  @media (max-width: 575px) {
    .container {
      max-width: 540px;
    }
    .ant-select-item{
      font-size: 12px;
    }
  }

  // sm
  @media (min-width: 576px) {
    .container {
      max-width: 668px;
    }
    .ant-select-item {
      font-size:  13.5px
    }

  }

  // md
  @media (min-width: 768px) {
    .container {
      max-width: 668px;
    }
    .ant-select-item {
      font-size:  13.5px
    }

  }

  // lg
  @media (min-width: 992px) {
    .container {
      max-width: 100%;
      padding: 0 20px;
    }
  }

  // xl
  @media (min-width: 1200px) {
    .container {
      max-width: 1100px;
    }
    .ant-select-item {
      font-size:  14px
    }
  }

  // xxl
  @media (min-width: 1600px) {
    .container {
      max-width: 1500px;
    }
    .ant-select-item {
      font-size:  15px
    }
  }
}

.apply-theme() {
  // .theme-GTBOrange {
  //   svg:not(.ant-empty-img-default) {
  //     path {
  //       fill: #f37802;
  //     }
  //     path[fill='white'] {
  //       fill: #fff;
  //     }
  //   }

  //   .ant-btn-primary:not([disabled]) {
  //     background: #f37802;
  //     border-color: #f37802;
  //   }
  //   .ant-btn-background-ghost.ant-btn-primary{
  //     background: transparent;
  //     color: #f37802
  //   }
  //   .ant-spin-dot-item {
  //     background: #f37802;
  //   }
  //   .ant-checkbox-checked .ant-checkbox-inner {
  //     background-color: #f37802;
  //     border-color: #f37802;
  //   }
  //   .ant-checkbox-checked::after {
  //     border: 1px solid #f37802;
  //   }
  //   .ant-picker-today-btn {
  //     color: #f37802;
  //     &:hover {
  //       color: #f37802;
  //     }
  //   }
  // }
  // .theme-ALATColour {
  //   svg:not(.ant-empty-img-default) {
  //     path {
  //       fill: #9D073D;
  //     }
  //     path[fill='white'] {
  //       fill: #fff;
  //     }
  //   }

  //   .ant-btn-primary:not([disabled]) {
  //     background: #9D073D;
  //     border-color: #9D073D;
  //   }
  //   .ant-btn-background-ghost.ant-btn-primary{
  //     background: transparent;
  //     color: #9D073D
  //   }
  //   .ant-spin-dot-item {
  //     background: #9D073D;
  //   }
  //   .ant-checkbox-checked .ant-checkbox-inner {
  //     background-color: #9D073D;
  //     border-color: #9D073D;
  //   }
  //   .ant-checkbox-checked::after {
  //     border: 1px solid #9D073D;
  //   }
  //   .ant-picker-today-btn {
  //     color: #9D073D;
  //     &:hover {
  //       color: #9D073D;
  //     }
  //   }
  // }

  // .theme-AccessBlue {
  //   svg:not(.ant-empty-img-default) {
  //     path {
  //       fill: #223E7C;
  //     }
  //     path[fill='white'] {
  //       fill: #fff;
  //     }
  //   }
  //   .ant-btn-primary:not([disabled]) {
  //     background: #223E7C;
  //     border-color: #223E7C;
  //   }
  //   .ant-btn-background-ghost.ant-btn-primary{
  //     background: transparent;
  //     color: #223E7C
  //   }
  //   .ant-spin-dot-item {
  //     background: #223E7C;
  //   }
  //   .ant-checkbox-checked .ant-checkbox-inner {
  //     background-color: #223E7C;
  //     border-color: #223E7C;
  //   }
  //   .ant-checkbox-checked::after {
  //     border: 1px solid #223E7C;
  //   }
  //   .ant-picker-today-btn {
  //     color: #223E7C;
  //     &:hover {
  //       color: #223E7C;
  //     }
  //   }
  // }

  // .theme-FidelityLemon {
  //   svg:not(.ant-empty-img-default) {
  //     path {
  //       fill: #87B655;
  //     }
  //     path[fill='white'] {
  //       fill: #fff;
  //     }
  //   }
  //   .ant-btn-primary:not([disabled]) {
  //     background: #87B655;
  //     border-color: #87B655;
  //   }
  //   .ant-btn-background-ghost.ant-btn-primary{
  //     background: transparent;
  //     color: #87B655
  //   }
  //   .ant-spin-dot-item {
  //     background: #87B655;
  //   }
  //   .ant-checkbox-checked .ant-checkbox-inner {
  //     background-color: #87B655;
  //     border-color: #87B655;
  //   }
  //   .ant-checkbox-checked::after {
  //     border: 1px solid #87B655;
  //   }
  //   .ant-picker-today-btn {
  //      color: #87B655;
  //      &:hover {
  //        color: #87B655;
  //      }
  //    }
  // }

  // .theme-FCMBPurple {
  //   svg:not(.ant-empty-img-default) {
  //     path {
  //       fill: #571A85;
  //     }
  //     path[fill='white'] {
  //       fill: #fff;
  //     }
  //   }
  //   .ant-btn-primary:not([disabled]) {
  //     background: #571A85;
  //     border-color: #571A85;
  //   }
  //   .ant-btn-background-ghost.ant-btn-primary{
  //     background: transparent;
  //     color: #571A85
  //   }
  //   .ant-spin-dot-item {
  //     background: #571A85;
  //   }
  //   .ant-checkbox-checked .ant-checkbox-inner {
  //     background-color: #571A85;
  //     border-color: #571A85;
  //   }
  //   .ant-checkbox-checked::after {
  //     border: 1px solid #571A85;
  //   }
  //   .ant-picker-today-btn {
  //     color: #571A85;
  //     &:hover {
  //       color: #571A85;
  //     }
  //   }
  // }

  // .theme-UBARed {
  //   svg:not(.ant-empty-img-default) {
  //     path {
  //       fill: #B00302;
  //     }
  //     path[fill='white'] {
  //       fill: #fff;
  //     }
  //   }
  //   .ant-btn-primary:not([disabled]) {
  //     background: #B00302;
  //     border-color: #B00302;
  //   }
  //   .ant-btn-background-ghost.ant-btn-primary{
  //     background: transparent;
  //     color: #B00302
  //   }
  //   .ant-spin-dot-item {
  //     background: #B00302;
  //   }
  //   .ant-checkbox-checked .ant-checkbox-inner {
  //     background-color: #B00302;
  //     border-color: #B00302;
  //   }
  //   .ant-checkbox-checked::after {
  //     border: 1px solid #B00302;
  //   }
  //   .ant-picker-today-btn {
  //      color: #B00302;
  //      &:hover {
  //        color: #B00302;
  //      }
  //    }
  // }

  // .theme-FBNBlue {
  //   svg:not(.ant-empty-img-default) {
  //     path {
  //       fill: #002E63;
  //     }
  //     path[fill='white'] {
  //       fill: #fff;
  //     }
  //   }
  //   .ant-btn-primary:not([disabled]) {
  //     background: #002E63;
  //     border-color: #002E63;
  //   }
  //   .ant-btn-background-ghost.ant-btn-primary{
  //     background: transparent;
  //     color: #002E63
  //   }
  //   .ant-spin-dot-item {
  //     background: #002E63;
  //   }
  //   .ant-checkbox-checked .ant-checkbox-inner {
  //     background-color: #002E63;
  //     border-color: #002E63;
  //   }
  //   .ant-checkbox-checked::after {
  //     border: 1px solid #002E63;
  //   }
  //   .ant-picker-today-btn {
  //      color: #002E63;
  //      &:hover {
  //        color: #002E63;
  //      }
  //    }
  // }

  // .theme-EcobankGreys {
  //   svg:not(.ant-empty-img-default) {
  //     path {
  //       fill: #3E6A8D;
  //     }
  //     path[fill='white'] {
  //       fill: #fff;
  //     }
  //   }
  //   .ant-btn-primary:not([disabled]) {
  //     background: #3E6A8D;
  //     border-color: #3E6A8D;
  //   }
  //   .ant-btn-background-ghost.ant-btn-primary{
  //     background: transparent;
  //     color: #3E6A8D
  //   }
  //   .ant-spin-dot-item {
  //     background: #3E6A8D;
  //   }
  //   .ant-checkbox-checked .ant-checkbox-inner {
  //     background-color: #3E6A8D;
  //     border-color: #3E6A8D;
  //   }
  //   .ant-checkbox-checked::after {
  //     border: 1px solid #3E6A8D;
  //   }
  //   .ant-picker-today-btn {
  //     color: #3E6A8D;
  //     &:hover {
  //       color: #3E6A8D;
  //     }
  //   }
  // }

  .theme-default {
    svg:not(.ant-empty-img-default) {
      path {
        fill: var(--primary-color);
      }
      path[fill='white'] {
        fill: #fff;
      }
    }
    .ant-btn {
      border-radius: 82px;
    }
    .ant-btn-primary:not([disabled]) {
      background: var(--primary-color);
      border-color: var(--primary-color);
    }
    .ant-btn-background-ghost.ant-btn-primary{
      background: transparent;
      color: var(--primary-color)
    }
    .ant-spin-dot-item {
      background: var(--primary-color);
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
    }
    .ant-checkbox-checked::after {
      border: 1px solid var(--primary-color);
    }
    .ant-picker-today-btn {
      color: var(--primary-color);
      &:hover {
        color: var(--primary-color);
      }
    }

    .ant-radio-checked .ant-radio-inner {
      border-color: var(--primary-color);
    }
    .ant-radio-inner::after {
      background-color: var(--primary-color);
    }
    .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
      border-color: var(--primary-color);
    }


  }
  .ant-notification-notice {
    background: #434455;
    padding: 8px 16px;
  }

  .ant-notification-notice-message {
    color: #fff;
  }

  .ant-notification-notice-close-x {
    svg {
      fill: #fff;
    }
  }

  .ant-notification-notice-close {
    top: 12px;
  }

  .ant-notification-notice-with-icon {

    .ant-notification-notice-icon {
      top: 11px;
    }

    .ant-notification-notice-message {
      margin-left: 35px;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .ant-progress-inner, .ant-progress-success-bg, .ant-progress-bg {
    border-radius: 0;
  }

  .ant-form-item {
    margin-bottom: 25px;
  }

  .ant-form-item-explain-error {
    color: #FF3B30;
    margin-bottom: 10px;
  }

  .ant-form-item-label > label {
    font-size: 15px;
    font-weight: 600;
  }

  .ant-picker-large .ant-picker-input > input {
    font-size: 12px;
    @media screen and (min-width: 1200px) {
      font-size: 14px;
      
    }
}

  .ant-alert-message {
    font-size: 14px;
  }

  .ant-picker-header-view button:hover {
    color: var(--primary-color);
  }

  .ant-drawer-title {
    font-size: 18px;
    font-weight: 500;
    color: #15162B;
  }

  .ant-drawer-close {
    margin-right: 0;
  }

}

.make-container();
.apply-theme();

// Utilities classes
.align-right {
  text-align: right;
}
.justify-center{
  justify-content: center;
}

@primary-color: #223E7C;@warning-color: #f5ae51;@error-color: #B90000;@text-color: #080403;@link-color: #223E7C;@input-placeholder-color: #949494;@font-size-base: 12px;@font-size-sm: 10px;@font-family: Gellix;@border-radius-base: 5px;@typography-title-font-weight: 700;@height-base: 40px;@height-lg: 48px;@height-sm: 32px;@line-height-base: 1.4;@select-single-item-height-lg: 48px;@select-multiple-item-height-lg: 40px;@select-dropdown-height-lg: 40px;@input-border-color: transparent;@select-border-color: transparent;@picker-border-color: transparent;@divider-orientation-margin: 2%;